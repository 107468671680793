import { render } from "solid-js/web";
import App from "./App.js";
import "solid-devtools";
import { attachDevtoolsOverlay } from "@solid-devtools/overlay";

render(() => <App />, document.getElementById("root")!);

if (import.meta.env.VITE_DEVTOOLS_OVERLAY === "true") {
  attachDevtoolsOverlay();
}
