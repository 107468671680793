import {
  catchError,
  createContext,
  createMemo,
  createResource,
  useContext,
} from "solid-js";
import { match } from "@formatjs/intl-localematcher";
import { Accessor } from "solid-js";
import { $settings } from "../settings/stores";
import { enGB } from "date-fns/locale/en-GB";
import { useStore } from "@nanostores/solid";
import type { Locale } from "date-fns";
import {
  resolveTemplate,
  translator,
  type Template,
} from "@solid-primitives/i18n";

export const SUPPORTED_LANGS = ["en", "zh-Hans"] as const;

export const SUPPORTED_REGIONS = ["en_US", "en_GB", "zh_CN"] as const;

const DEFAULT_LANG = "en";

/**
 * Decide the using language for the user.
 *
 * **Performance**: This function is costy, make sure you cache the result.
 * In the app, you should use {@link useAppLocale} instead.
 *
 * @returns the selected language tag
 */
export function autoMatchLangTag() {
  return match(Array.from(navigator.languages), SUPPORTED_LANGS, DEFAULT_LANG);
}

/**
 * Decide the using region for the user.
 *
 * **Performance**: This function is costy, make sure you cache the result.
 * In the app, you should use {@link useAppLocale} instead.
 */
export function autoMatchRegion() {
  const specifiers = navigator.languages.map((x) => x.split("-"));

  for (const s of specifiers) {
    if (s.length === 1) {
      const lang = s[0];
      for (const available of SUPPORTED_REGIONS) {
        if (available.toLowerCase().startsWith(lang.toLowerCase())) {
          return available;
        }
      }
    } else if (s.length === 2) {
      const [lang, region] = s;
      for (const available of SUPPORTED_REGIONS) {
        if (available.toLowerCase() === `${lang}_${region}`.toLowerCase()) {
          return available;
        }
      }
    }
  }

  return "en_GB";
}

export function createCurrentRegion() {
  const appSettings = useStore($settings);

  return createMemo(
    () => {
      const settings = appSettings();
      if (typeof settings.region !== "undefined") {
        return settings.region;
      } else {
        return autoMatchRegion();
      }
    },
    "en_GB",
    { name: "region" },
  );
}

async function importDateFnLocale(tag: string): Promise<Locale> {
  switch (tag.toLowerCase()) {
    case "en_us":
      return (await import("date-fns/locale/en-US")).enUS;
    case "en_gb":
      return enGB;
    case "zh_cn":
      return (await import("date-fns/locale/zh-CN")).zhCN;
    default:
      throw new TypeError(`unsupported tag "${tag}"`);
  }
}

/**
 * Get the {@link Locale} object for date-fns.
 *
 * This function must be using in {@link DateFnScope}
 *
 * @returns Accessor for Locale
 */
export function useDateFnLocale(): Accessor<Locale> {
  const { dateFn } = useAppLocale();
  return dateFn;
}

export function createCurrentLanguage() {
  const settings = useStore($settings);
  return createMemo(() => settings().language || autoMatchLangTag());
}

type ImportFn<T> = (name: string) => Promise<{ default: T }>;

type ImportedModule<F> = F extends ImportFn<infer T> ? T : never;

type MergedImportedModule<T> = T extends []
  ? {}
  : T extends [infer I]
    ? ImportedModule<I>
    : T extends [infer I, ...infer J]
      ? ImportedModule<I> & MergedImportedModule<J>
      : never;

/**
 * Create a resource that combines all I18N strings into one object.
 *
 * The result is combined in the order of the argument functions.
 * The formers will be overrided by the latter.
 *
 * @param importFns a series of functions imports the string modules
 * based on the specified language code.
 *
 * **Context**: This function must be used under {@link AppLocaleProvider}.
 *
 * @example ````ts
 * const [strings] = createStringResource(
 *   async (code) => await import(`./i18n/${code}.json`), // Vite can handle the bundling
 *   async () => import("./i18n/generic.json"), // You can also ignore the code.
 * );
 * ````
 *
 * @see {@link createTranslator} if you need a Translator from "@solid-primitives/i18n"
 */
export function createStringResource<
  T extends ImportFn<Record<string, string | Template<any> | undefined>>[],
>(...importFns: T) {
  const { language } = useAppLocale();
  const cache: Record<string, MergedImportedModule<T>> = {};

  return createResource(
    () => [language()] as const,
    async ([nlang]) => {
      if (cache[nlang]) {
        return cache[nlang];
      }

      const results = await Promise.all(
        importFns.map((x) => x(nlang).then((v) => v.default)),
      );

      const merged: MergedImportedModule<T> = Object.assign({}, ...results);

      cache[nlang] = merged;

      return merged;
    },
  );
}

/**
 * Create the Translator from "@solid-primitives/i18n" based on
 * the {@link createStringResource}.
 *
 * @param importFns same to {@link createStringResource}
 *
 * @returns the first element is the translator, the second is the result from
 * {@link createStringResource}.
 *
 * @see {@link translator} for the translator usage
 * @see {@link createStringResource} for the raw strings
 */
export function createTranslator<
  T extends ImportFn<Record<string, string | Template<any> | undefined>>[],
>(...importFns: T) {
  const res = createStringResource(...importFns);

  return [translator(res[0], resolveTemplate), res] as const;
}

export type AppLocale = {
  dateFn: () => Locale;
  language: () => string;
  region: () => string;
};

const AppLocaleContext = /* @__PURE__ */ createContext<AppLocale>();

export const AppLocaleProvider = AppLocaleContext.Provider;

export function useAppLocale() {
  const l = useContext(AppLocaleContext);
  if (!l) {
    throw new TypeError("app locale not found");
  }
  return l;
}

export function createDateFnLocaleResource(region: () => string) {
  const [localeUncaught] = createResource(
    region,
    async (region) => {
      return await importDateFnLocale(region);
    },
    { initialValue: enGB },
  );

  return createMemo(
    () =>
      catchError(localeUncaught, (reason) => {
        console.error("fetch date-fns locale", reason);
      }) ?? enGB,
  );
}
