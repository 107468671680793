//! This module has side effect.
//! It recommended to include the module by <script> tag.
if (typeof window.crypto.randomUUID === "undefined") {
  // TODO: this polyfill can be removed in 2.0, see https://code.lightstands.xyz/Rubicon/tutu/issues/36
  // Chrome/Edge 92+
  // https://stackoverflow.com/a/2117523/2800218
  // LICENSE: https://creativecommons.org/licenses/by-sa/4.0/legalcode
  window.crypto.randomUUID =
    function randomUUID(): `${string}-${string}-${string}-${string}-${string}` {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
        (
          +c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
        ).toString(16),
      ) as `${string}-${string}-${string}-${string}-${string}`;
    };
}


if (typeof Promise.withResolvers === "undefined") {
  // Chrome/Edge 119, Firefox 121, Safari/iOS 17.4

  // Promise.withResolvers is generic and works with subclasses - the typescript built-in decl
  // could not handle the subclassing case.
  (Promise.prototype as any).withResolvers = function <T>(this: AnyPromiseConstructor<T>) {
    let resolve!: PromiseWithResolvers<T>["resolve"], reject!: PromiseWithResolvers<T>["reject"];
    // These variables are expected to be set after `new this()`

    const promise = new this((resolve0, reject0) => {
      resolve = resolve0;
      reject = reject0;
    })

    return {
      promise, resolve, reject
    }
  }
}
