import { Theme, createTheme } from "@suid/material/styles";
import { deepPurple, amber, red } from "@suid/material/colors";
import { Accessor } from "solid-js";

/**
 * The MUI theme.
 */
export function createRootTheme(): Accessor<Theme> {
  const theme = createTheme({
    palette: {
      primary: {
        main: deepPurple[500],
      },
      error: {
        main: red[900],
      },
      secondary: {
        main: amber.A200,
      },
    },
  });

  return () => theme;
}

export const ANIM_CURVE_STD = "cubic-bezier(0.4, 0, 0.2, 1)";
export const ANIM_CURVE_DECELERATION = "cubic-bezier(0, 0, 0.2, 1)";
export const ANIM_CURVE_ACELERATION = "cubic-bezier(0.4, 0, 1, 1)";
export const ANIM_CURVE_SHARP = "cubic-bezier(0.4, 0, 0.6, 1)";
