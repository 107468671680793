import { persistentAtom } from "@nanostores/persistent";
import {
  createOAuthAPIClient,
  createRestAPIClient,
  type mastodon,
} from "masto";
import { createMastoClientFor } from "../masto/clients";

export type RemoteServer = {
  site: string;
};

export type AccountKey = RemoteServer & {
  accessToken: string;
};

export function isAccountKey(object: RemoteServer): object is AccountKey {
  return !!(object as Record<string, unknown>)["accessToken"];
}

export type Account = AccountKey & {
  tokenType: string;
  scope: string;
  createdAt: number;

  inf?: mastodon.v1.AccountCredentials;
};

export function isAccount(object: RemoteServer) {
  return isAccountKey(object) && !!(object as Record<string, unknown>)["tokenType"];
}

export const $accounts = persistentAtom<Account[]>("accounts", [], {
  encode: JSON.stringify,
  decode: JSON.parse,
});

interface OAuth2AccessToken {
  access_token: string;
  token_type: string;
  scope: string;
  created_at: number;
}

async function oauth2TokenViaAuthCode(app: RegisteredApp, authCode: string) {
  const resp = await fetch(new URL("./oauth/token", app.site), {
    method: "post",
    body: JSON.stringify({
      grant_type: "authorization_code",
      code: authCode,
      client_id: app.clientId,
      client_secret: app.clientSecret,
      redirect_uri: app.redirectUrl,
      scope: "read write push",
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  switch (resp.status) {
    case 200:
      return (await resp.json()) as OAuth2AccessToken;
    default: {
      const dict = await resp.json();
      const explain = dict.error_desciption ?? "Unknown OAuth2 Error";
      throw new TypeError(explain);
    }
  }
}

export async function acceptAccountViaAuthCode(site: string, authCode: string) {
  const $store = $accounts;
  const app = $registeredApps.get()[site];
  if (!app) {
    throw TypeError("application not found");
  }
  const token = await oauth2TokenViaAuthCode(app, authCode);

  const acct = {
    site: app.site,
    accessToken: token.access_token,
    tokenType: token.token_type,
    scope: token.scope,
    createdAt: token.created_at * 1000,
  };

  const all = [...$store.get(), acct];
  $store.set(all);

  return acct;
}

export async function updateAcctInf(idx: number) {
  const o = $accounts.get();
  const client = createMastoClientFor(o[idx]);
  const inf = await client.v1.accounts.verifyCredentials();
  o[idx] = Object.assign({}, o[idx], { inf });
  $accounts.set(Array.from(o));
  return inf;
}

export function signOut(predicate: (acct: Account) => boolean) {
  $accounts.set($accounts.get().filter((a) => !predicate(a)));
}

export type RegisteredApp = {
  site: string;
  clientId: string;
  clientSecret: string;
  vapidKey?: string;
  redirectUrl: string;
  scope: string;
};

export const $registeredApps = persistentAtom<{
  [site: string]: RegisteredApp;
}>(
  "registeredApps",
  {},
  {
    encode: JSON.stringify,
    decode: JSON.parse,
  },
);

async function getAppAccessToken(app: RegisteredApp) {
  const resp = await fetch(new URL("./oauth/token", app.site), {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      client_id: app.clientId,
      client_secret: app.clientSecret,
      redirect_uri: app.redirectUrl,
      grant_type: "client_credentials",
    }),
  });
  const dict = await resp.json();
  return dict.access_token;
}

export async function getOrRegisterApp(site: string, redirectUrl: string) {
  const $store = $registeredApps;
  const all = $store.get();
  const savedApp = all[site];
  if (savedApp && savedApp.redirectUrl === redirectUrl) {
    const appAccessToken = await getAppAccessToken(savedApp);
    if (appAccessToken) {
      const client = createRestAPIClient({
        url: site,
        accessToken: appAccessToken,
      });
      try {
        const verify = await client.v1.apps.verifyCredentials();
        Object.assign(savedApp, {
          vapidKey: verify.vapidKey,
        });
        const oauthClient = createOAuthAPIClient({
          url: site,
          accessToken: appAccessToken,
        });
        try {
          await oauthClient.revoke({
            clientId: savedApp.clientId,
            clientSecret: savedApp.clientSecret,
            token: appAccessToken,
          });
        } catch {}
        return savedApp;
      } finally {
        $store.set(all);
      }
    }
  }

  const client = createRestAPIClient({
    url: site,
  });
  const app = await client.v1.apps.create({
    clientName: "TuTu",
    website: "https://code.lightstands.xyz/Rubicon/tutu",
    redirectUris: redirectUrl,
    scopes: "read write push",
  });
  if (!app.clientId || !app.clientSecret) {
    return null;
  }
  all[site] = {
    site,
    clientId: app.clientId,
    clientSecret: app.clientSecret,
    vapidKey: app.vapidKey ?? undefined,
    redirectUrl: redirectUrl,
    scope: "read write push",
  };
  $store.set(all);
  return all[site];
}
